import React, { Component } from 'react';
import { NavigateFunction } from 'react-router-dom';
import Row from 'react-bootstrap/Row';
import Button from 'react-bootstrap/Button';
import Container from 'react-bootstrap/Container';
import Col from 'react-bootstrap/Col';
import Accordion from 'react-bootstrap/Accordion';
import FormControl from 'react-bootstrap/FormControl';
import InputGroup from 'react-bootstrap/InputGroup';
import Stack from 'react-bootstrap/Stack';
import { GiBookCover, GiMagicLamp } from 'react-icons/gi';
import { BiSearch } from 'react-icons/bi';
import Modal from 'react-bootstrap/Modal';
import { IoTrashOutline } from 'react-icons/io5';
import { APIClient } from '../APIClient';
import { SavedBook } from '../interfaces/SavedBook';
import { getSavedBooks, removeBook } from '../components/Library';
import { SHARE_CHANNELS, shareStory, renderButton } from '../components/Share';

interface LibraryState {
  allBooks?: SavedBook[];
  books?: SavedBook[];
  searchText: string;
  bookIdToRemove?: string;
}

// TODO
// Option to remove book

function msToReadableDelta(ms: number) {
  const nowMs = Date.now();
  const deltaMs = nowMs - ms;
  const seconds = Math.floor(deltaMs / 1000);
  const minutes = Math.floor(seconds / 60);
  const hours = Math.floor(minutes / 60);
  const days = Math.floor(hours / 24);
  if (days > 0) {
    return `${days} day${days > 1 ? 's' : ''}  ago`;
  }
  if (hours > 0) {
    return `${hours} hour${hours > 1 ? 's' : ''} ago`;
  }
  if (minutes > 0) {
    return `${minutes} minute${minutes > 1 ? 's' : ''} ago`;
  }
  return 'less than a minute ago';
}

export default class Library extends Component<{
  apiClient: APIClient,
  navigate: NavigateFunction,
 }> {
  apiClient: APIClient;

  state: LibraryState;

  navigate: NavigateFunction;

  constructor(props: { apiClient: APIClient, navigate: NavigateFunction }) {
    super(props);
    this.apiClient = props.apiClient;
    this.navigate = props.navigate;

    this.state = {
      searchText: '',
    };

    this.refresh = this.refresh.bind(this);
    this.viewBook = this.viewBook.bind(this);
    this.renderBookList = this.renderBookList.bind(this);
    this.navigateToNewStory = this.navigateToNewStory.bind(this);
    this.searchTextChanged = this.searchTextChanged.bind(this);
    this.setBookIdToRemove = this.setBookIdToRemove.bind(this);
  }

  componentDidMount() {
    this.refresh();
  }

  async setBookIdToRemove(bookIdToRemove?: string) {
    await this.setState((s) => ({ ...s, bookIdToRemove }));
  }

  navigateToNewStory() {
    this.navigate('/');
  }

  viewBook(bookId) {
    this.navigate(`/book/${bookId}`);
  }

  refresh(): void {
    const allBooks = getSavedBooks();
    allBooks.sort((a, b) => (b.createdAtMs - a.createdAtMs));
    this.setState((s) => ({ ...s, allBooks, books: allBooks }));
  }

  searchTextChanged(e) {
    const searchText = e.target.value;
    const { allBooks } = this.state;
    const cleanSearchText = searchText.trim().toLowerCase();
    const books = allBooks?.filter((book) => {
      if (!cleanSearchText) {
        return true;
      }
      const haystack = `${book.title} ${book.plot} ${book.bookId}`;
      return haystack.toLowerCase().includes(cleanSearchText);
    });
    this.setState((s) => ({ ...s, searchText, books }));
  }

  renderBookList(books: SavedBook[], totalBookCount: number) {
    const { searchText } = this.state;
    return (
      <>
        <p>
          You have
          {` ${totalBookCount} ${totalBookCount === 1 ? 'story ' : 'stories '}`}
          in your library. Click on one below to get more details and read it again.
        </p>
        <InputGroup className="mb-3">
          <InputGroup.Text><BiSearch /></InputGroup.Text>
          <FormControl
            placeholder="Search library"
            value={searchText}
            onChange={this.searchTextChanged}
          />
          <InputGroup.Text>
            showing
            {' '}
            {books.length}
            /
            {totalBookCount}
          </InputGroup.Text>
        </InputGroup>
        <Accordion>
          {
          books.map((book) => (
            <Accordion.Item eventKey={book.bookId} key={book.bookId}>
              <Accordion.Header>{book.title}</Accordion.Header>
              <Accordion.Body>
                <Container>
                  <Row>
                    <Col md="auto">
                      <img
                        src={this.apiClient.getPageImageUrl(book.bookId, 1)}
                        height="200"
                        alt="story cover"
                      />
                    </Col>
                    <Col>
                      <Stack style={{ height: '85%' }}>
                        <Stack direction="horizontal">
                          <div>{book.plot}</div>
                          <Stack direction="horizontal" className="ms-auto">
                            {
                              SHARE_CHANNELS.map((channel, index) => (
                                <div key={channel.name} className={index ? 'ms-1' : 'ms-auto'}>
                                  {
                                    renderButton(
                                      channel,
                                      () => shareStory(channel, book.bookId, book.title),
                                    )
                                  }
                                </div>
                              ))
                            }
                          </Stack>
                        </Stack>
                        <Button
                          variant="success"
                          className="me-auto m-2"
                          onClick={() => this.viewBook(book.bookId)}
                        >
                          <GiBookCover />
                          {' '}
                          Let&apos;s read
                        </Button>
                      </Stack>
                      <Stack direction="horizontal">
                        <div className="mt-2">
                          <i>
                            {book.amIAuthor ? 'Created by me' : 'Read by me'}
                            {' '}
                            {msToReadableDelta(book.createdAtMs)}
                          </i>
                        </div>
                        <Button
                          onClick={() => this.setBookIdToRemove(book.bookId)}
                          variant="danger"
                          size="sm"
                          className="ms-auto"
                        >
                          <IoTrashOutline />
                          {' '}
                          Remove Story
                        </Button>
                      </Stack>
                    </Col>
                  </Row>
                </Container>
              </Accordion.Body>
            </Accordion.Item>
          ))
        }
        </Accordion>
      </>
    );
  }

  render() {
    const { books, allBooks, bookIdToRemove } = this.state;
    if (!books || !allBooks) {
      return <div>Loading...</div>;
    }
    return (
      <div>
        <Modal centered show={!!bookIdToRemove} onHide={() => this.setBookIdToRemove()}>
          <Modal.Header closeButton>
            <Modal.Title>Remove Book</Modal.Title>
          </Modal.Header>
          <Modal.Body>
            <p>Are you sure you want to remove this story from your library?</p>
            <p>People that the story was shared with can still view it.</p>
          </Modal.Body>
          <Modal.Footer>
            <Button variant="secondary" onClick={() => this.setBookIdToRemove()}>
              Cancel
            </Button>
            <Button
              variant="danger"
              onClick={() => {
                removeBook(bookIdToRemove || '');
                this.setBookIdToRemove();
                this.refresh();
              }}
            >
              Remove
            </Button>
          </Modal.Footer>
        </Modal>
        <h1>Library</h1>
        <div>
          {allBooks.length ? this.renderBookList(books, allBooks.length)
            : (
              <div style={{ textAlign: 'center' }}>
                <h4>Empty Library</h4>
                <p>It seems you don&apos;t have any stories saved.</p>
                <p>That&apos;s OK - use your imagination to create a new one!</p>
                <Button
                  onClick={this.navigateToNewStory}
                  variant="success"
                  className="btn-lg"
                >
                  <GiMagicLamp />
                  {' '}
                  New Story
                </Button>
              </div>
            )}
        </div>
      </div>
    );
  }
}
