import React, { Component } from 'react';
import Row from 'react-bootstrap/Row';
import Col from 'react-bootstrap/Col';
import InputGroup from 'react-bootstrap/InputGroup';
import Dropdown from 'react-bootstrap/Dropdown';
import FormControl from 'react-bootstrap/FormControl';
import Container from 'react-bootstrap/Container';
import Button from 'react-bootstrap/Button';
import Stack from 'react-bootstrap/Stack';
import Accordion from 'react-bootstrap/Accordion';
import { NavigateFunction } from 'react-router-dom';
import DropdownButton from 'react-bootstrap/DropdownButton';
import { GiMagicLamp } from 'react-icons/gi';
import AccordionBody from 'react-bootstrap/esm/AccordionBody';
import { APIClient } from '../APIClient';
import Jenny from '../images/jenny.png';
import Loading from '../components/Loading';
import { addPendingBookId } from '../components/Library';

const IMAGE_STYLE_PRESETS = [
  ['Digital Art', 'digital-art'],
  ['Photographic', 'photographic'],
  ['Cinematic', 'cinematic'],
  ['3D Model', '3d-model'],
  ['Analog Film', 'analog-film'],
  ['Comic Book', 'comic-book'],
  ['Fantasy Art', 'fantasy-art'],
  ['Line Art', 'line-art'],
  ['Origami', 'origami'],
  ['Tile Texture', 'tile-texture'],
];

const IMAGE_STYLE_PRESETS_INDEX_MAP = IMAGE_STYLE_PRESETS
  .reduce((prev, curr, index) => ({ ...prev, [curr[1]]: index }), {});

interface CreateBookState {
  bookId?: string;
  plot: string;
  characters: string;
  durationMinutes: string;
  childYearsOld: string;
  inspirationBook: string;
  inspirationAuthor: string;
  imageStylePresetIndex: number;
  fromBookId?: string;
  isLoading: boolean;
}

export default class CreateBook extends Component<
{ apiClient: APIClient, navigate: NavigateFunction, fromBookId?: string }
> {
  apiClient: APIClient;

  state: CreateBookState;

  navigate: NavigateFunction;

  constructor(props: { apiClient: APIClient, navigate: NavigateFunction, fromBookId?: string }) {
    super(props);
    this.apiClient = props.apiClient;
    this.navigate = props.navigate;
    this.state = {
      plot: '',
      characters: '',
      durationMinutes: '',
      childYearsOld: '',
      inspirationBook: '',
      inspirationAuthor: '',
      bookId: undefined,
      imageStylePresetIndex: 0,
      fromBookId: props.fromBookId,
      isLoading: true,
    };

    this.onValueChange = this.onValueChange.bind(this);
    this.onSubmit = this.onSubmit.bind(this);
  }

  async componentDidMount(): Promise<void> {
    const { fromBookId } = this.state;
    if (fromBookId) {
      const bookData = await this.apiClient.getBookData(fromBookId);
      if (bookData) {
        this.setState((s) => ({
          ...s,
          plot: bookData.input.plot,
          characters: bookData.input.characters,
          durationMinutes: bookData.input.durationMinutes ? bookData.input.durationMinutes.toString() : '',
          childYearsOld: bookData.input.childYearsOld ? bookData.input.childYearsOld.toString() : '',
          inspirationBook: bookData.input.inspirationBook,
          inspirationAuthor: bookData.input.inspirationAuthor,
          imageStylePresetIndex: IMAGE_STYLE_PRESETS_INDEX_MAP[bookData.input.imageStylePreset],
        }));
      } else {
        console.log(`Book with ID '${fromBookId}' not found`);
      }
    }

    this.setState((s) => ({ ...s, isLoading: false }));
  }

  onValueChange(property, event) {
    this.setState((s) => ({ ...s, [property]: event.target.value }));
  }

  async onSubmit() {
    const {
      plot,
      characters,
      durationMinutes,
      childYearsOld,
      inspirationBook,
      inspirationAuthor,
      imageStylePresetIndex,
    } = this.state;

    if (!plot || !plot.trim()) {
      alert('Must provide a plot');
      return;
    }
    const finalPlot = plot;
    let finalCharacters: string|null = null;
    let finalDurationMinutes: number|null = null;
    let finalChildYearsOld: number|null = null;
    let finalInspirationBook: string|null = null;
    let finalInspirationAuthor: string|null = null;

    if (characters && characters.trim()) {
      finalCharacters = characters.trim();
    }

    if (durationMinutes) {
      finalDurationMinutes = parseInt(durationMinutes, 10);
      if (Number.isNaN(finalDurationMinutes)) {
        alert('Duration minutes is an invalid number');
        return;
      }
    }

    if (childYearsOld) {
      finalChildYearsOld = parseInt(childYearsOld, 10);
      if (Number.isNaN(finalChildYearsOld)) {
        alert('Child age is an invalid number');
        return;
      }
    }

    if (inspirationBook && inspirationBook.trim()) {
      finalInspirationBook = inspirationBook.trim();
    }
    if (inspirationAuthor && inspirationAuthor.trim()) {
      finalInspirationAuthor = inspirationAuthor.trim();
    }

    const bookId = await this.apiClient.createBook(
      finalPlot,
      finalCharacters,
      finalDurationMinutes,
      finalChildYearsOld,
      finalInspirationBook,
      finalInspirationAuthor,
      IMAGE_STYLE_PRESETS[imageStylePresetIndex][1],
    );

    addPendingBookId(bookId);

    this.navigate(`/book/${bookId}`);
  }

  render() {
    const {
      bookId,
      plot,
      characters,
      durationMinutes,
      childYearsOld,
      inspirationBook,
      imageStylePresetIndex,
      isLoading,
    } = this.state;

    return (
      <>
        <h1>Create a new book</h1>
        <Container fluid>
          <Row>
            <Col md="auto">
              <img src={Jenny} alt="Jenny" width="300" />
            </Col>
            <Col>
              {isLoading ? <Loading />
                : (
                  <>
                    <InputGroup className="mb-2">
                      <InputGroup.Text>Plot</InputGroup.Text>
                      <FormControl value={plot} placeholder="Enter the plot for your story" onChange={(e) => this.onValueChange('plot', e)} />
                    </InputGroup>
                    <Accordion>
                      <Accordion.Item eventKey="0">
                        <Accordion.Header>
                          <i>Expand for more details (optional)</i>
                        </Accordion.Header>
                        <AccordionBody>
                          <Stack>
                            <InputGroup className="mb-2">
                              <InputGroup.Text>Characters</InputGroup.Text>
                              <FormControl value={characters} placeholder="Who is your story about (separate with 'and')" onChange={(e) => this.onValueChange('characters', e)} />
                            </InputGroup>
                            <InputGroup className="mb-2">
                              <InputGroup.Text>Duration in Minutes</InputGroup.Text>
                              <FormControl value={durationMinutes} placeholder="How long should your story be?" onChange={(e) => this.onValueChange('durationMinutes', e)} />
                            </InputGroup>
                            <InputGroup className="mb-2">
                              <InputGroup.Text>Child Age</InputGroup.Text>
                              <FormControl value={childYearsOld} placeholder="How old (in years) is the target audience?" onChange={(e) => this.onValueChange('childYearsOld', e)} />
                            </InputGroup>
                            <InputGroup className="mb-2">
                              <InputGroup.Text>Inspired By Book</InputGroup.Text>
                              <FormControl value={inspirationBook} placeholder="Title of book to use as inspiration" onChange={(e) => this.onValueChange('inspirationBook', e)} />
                            </InputGroup>
                            <InputGroup className="mb-2">
                              <InputGroup.Text>Image Style Preset</InputGroup.Text>
                              <DropdownButton title={IMAGE_STYLE_PRESETS[imageStylePresetIndex][0]}>
                                {
                    IMAGE_STYLE_PRESETS.map((imageStylePreset, index) => (
                      <Dropdown.Item
                        key={imageStylePreset[1]}
                        onClick={() => this.onValueChange('imageStylePresetIndex', { target: { value: index } })}
                      >
                        {imageStylePreset[0]}
                      </Dropdown.Item>
                    ))
                  }
                              </DropdownButton>
                            </InputGroup>
                          </Stack>
                        </AccordionBody>
                      </Accordion.Item>
                    </Accordion>
                    <Button
                      variant="success"
                      onClick={this.onSubmit}
                      disabled={!!bookId}
                      className="mt-3 btn-lg"
                    >
                      <GiMagicLamp />
                      {' '}
                      Create Story
                    </Button>
                  </>
                )}
            </Col>
          </Row>
        </Container>
      </>
    );
  }
}
