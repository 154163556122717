import React, { Component } from 'react';
import Row from 'react-bootstrap/Row';
import Col from 'react-bootstrap/Col';
import Button from 'react-bootstrap/Button';
import Container from 'react-bootstrap/Container';
import ReactPlayer from 'react-player';
import { GrLinkNext, GrLinkPrevious } from 'react-icons/gr';
import ProgressBar from 'react-bootstrap/ProgressBar';

import Stack from 'react-bootstrap/Stack';
import { GiMagicLamp } from 'react-icons/gi';
import { NavigateFunction } from 'react-router-dom';
import { APIClient, BookStatus } from '../APIClient';
import Loading from '../components/Loading';
import { AudioMetadata } from '../interfaces/AudioMetadata';
import {
  doesBookExist, getPendingBookIds, saveBook, removePendingBookId,
} from '../components/Library';
import { SHARE_CHANNELS, shareStory, renderButton } from '../components/Share';

const isHebrew = (str) => /[\u0590-\u05FF]/.test(str);
const STATUS_MESSAGES = {
  initializing: "That's a great idea, let me get started",
  creating: "I'm thinking...",
  transcribing: "I'm recording my voice and drawing some pictures...",
  ready: 'All set!',
  queued: "I'm busy helping others right now, I'll be with you in a few moments. Sit tight and get the hot chocolate ready!",
};
interface BookState {
  status?: BookStatus;
  bookId: string;
  bookData: any;
  selectedPage: number;
  audioUrl?: string;
  isAudioAvailable?: boolean;
  audioMetadata?: AudioMetadata;
  imageUrl?: string;
  textIndexes: number[];
}

export default class Book extends Component<{
  apiClient: APIClient,
  navigate: NavigateFunction,
  bookId: string,
 }> {
  apiClient: APIClient;

  state: BookState;

  navigate: NavigateFunction;

  constructor(props: { apiClient: APIClient, navigate: NavigateFunction, bookId: string }) {
    super(props);
    this.apiClient = props.apiClient;
    this.navigate = props.navigate;
    this.state = {
      status: undefined,
      bookId: props.bookId,
      bookData: null,
      selectedPage: 1,
      audioMetadata: undefined,
      isAudioAvailable: undefined,
      audioUrl: undefined,
      imageUrl: undefined,
      textIndexes: [0, 0],
    };
    this.onPageCompleted = this.onPageCompleted.bind(this);
    this.onProgressAudio = this.onProgressAudio.bind(this);
    this.incrementPage = this.incrementPage.bind(this);
    this.pollStatus = this.pollStatus.bind(this);
    this.shareStory = this.shareStory.bind(this);
    this.regenerateStory = this.regenerateStory.bind(this);
  }

  async componentDidMount(): Promise<void> {
    this.pollStatus();
  }

  onPageCompleted() {
    const { bookData, selectedPage } = this.state;
    console.log('The page has been completed', { selectedPage });
    if (selectedPage >= bookData.pages.length) {
      console.log('No more pages');
      return;
    }

    this.setState((s) => ({ ...s, selectedPage: selectedPage + 1, textIndexes: [0, 0] }));
    this.loadPageData(selectedPage + 1);
  }

  onProgressAudio(data) {
    const timeIndexMs = data.playedSeconds * 1000;
    const { audioMetadata } = this.state;
    if (!audioMetadata) {
      return;
    }
    const thisTimeIndex = audioMetadata.times.findIndex(
      (timeData) => timeData.time > timeIndexMs - 1,
    );
    const thisTime = audioMetadata.times[thisTimeIndex];
    if (!thisTime) {
      return;
    }
    const textIndexes = [thisTime.start, thisTime.end];
    this.setState((s) => ({ ...s, textIndexes }));
  }

  regenerateStory() {
    const { bookId } = this.state;
    this.navigate(`/?fromBookId=${bookId}`);
  }

  shareStory(channel) {
    const { bookData } = this.state;
    shareStory(channel, bookData.bookId, bookData.title);
  }

  async pollStatus() {
    const { bookId } = this.state;
    if (!bookId) {
      throw new Error('Book ID is not found');
    }
    let status;
    try {
      status = await this.apiClient.getBookStatus(bookId);
    } catch (err) {
      console.error('Error fetching status', err);
    }
    console.log(`Status = ${status}`);
    if (status && status.status === 'ready') {
      this.showBook();
    } else if (status) {
      this.setState((s) => ({ ...s, status }), () => setTimeout(this.pollStatus, 3000));
    } else {
      setTimeout(this.pollStatus, 3000);
    }
  }

  async showBook() {
    const { bookId } = this.state;
    const bookData = await this.apiClient.getBookData(bookId);
    console.log('Loading book data');

    if (bookData && !doesBookExist(bookId)) {
      const pendingBookIds = getPendingBookIds();
      const amIAuthor = !!pendingBookIds[bookId];
      console.log(`Saved book to library, amIAuthor = ${amIAuthor}`);
      saveBook(bookId, bookData.input.plot || '', bookData.title, bookData.pages.length, amIAuthor);
      if (amIAuthor) {
        removePendingBookId(bookId);
      }
    }

    this.setState((s) => ({ ...s, bookData, status: { status: 'ready' } }));
    await this.loadPageData(1);
  }

  incrementPage(increment) {
    const { selectedPage } = this.state;
    this.setState((s) => ({ ...s, selectedPage: selectedPage + increment, textIndexes: [0, 0] }));
    this.loadPageData(selectedPage + increment);
  }

  async loadPageData(pageNumber) {
    const { bookId } = this.state;

    await this.setState((s) => ({
      ...s, audioUrl: undefined, audioMetadata: undefined, imageUrl: undefined, textIndexes: [0, 0],
    }));
    const getPageAudioUrl = async () => {
      const audioUrl = this.apiClient.getPageAudioUrl(bookId, pageNumber);
      await this.setState((s) => ({ ...s, audioUrl }));
    };
    const getPageAudioMetadata = async () => {
      const audioMetadata = await this.apiClient.getPageAudioMetadata(bookId, pageNumber);
      await this.setState((s) => ({ ...s, audioMetadata, isAudioAvailable: !!audioMetadata }));
    };
    const getPageImageUrl = async () => {
      const imageUrl = this.apiClient.getPageImageUrl(bookId, pageNumber);
      await this.setState((s) => ({ ...s, imageUrl }));
    };
    const promises = [
      getPageAudioMetadata(),
      getPageAudioUrl(),
      getPageImageUrl(),
    ];

    await Promise.all(promises);
  }

  render() {
    const {
      bookData, selectedPage, audioUrl, textIndexes, imageUrl, status,
      isAudioAvailable,
    } = this.state;

    let transcribedCount = 0;
    let drewCount = 0;
    let transcribedPercentage = 0;
    let drewPercentage = 0;
    let pageCount = 0;
    if (status?.pages) {
      pageCount = status.pages.length;
      transcribedCount = status.pages.filter((p) => p.transcribe === 'COMPLETED').length;
      drewCount = status.pages.filter((p) => p.draw === 'COMPLETED').length;
      transcribedPercentage = (transcribedCount * 100) / pageCount;
      drewPercentage = (drewCount * 100) / pageCount;
    }

    if (!status || status.status !== 'ready') {
      return (
        <div>
          {status?.title ? <h1>{status?.title}</h1> : undefined}
          <Loading />
          <h3>
            {STATUS_MESSAGES[!status ? 'initializing' : status.status]}
          </h3>
          {
            status && status.pages ? (
              <Container>
                <Row>
                  <Col>
                    {`Transcribed ${transcribedCount} of ${status.pages.length} recordings`}
                    {
                      transcribedPercentage < 100
                        ? (
                          <ProgressBar
                            animated
                            now={transcribedPercentage}
                            variant="info"
                            className="mb-2"
                          />
                        )
                        : (
                          <ProgressBar
                            now={100}
                            variant="info"
                            className="mb-2"
                          />
                        )
                    }
                  </Col>
                </Row>
                <Row>
                  <Col>
                    {`Illustrated ${drewCount} of ${status.pages.length} images`}

                    {
                      drewPercentage < 100
                        ? (
                          <ProgressBar
                            animated
                            now={drewPercentage}
                            variant="info"
                            className="mb-2"
                          />
                        )
                        : (
                          <ProgressBar
                            now={100}
                            variant="info"
                            className="mb-2"
                          />
                        )
                    }
                  </Col>
                </Row>
              </Container>
            ) : undefined
          }
          <h5>This could take a while, go grab a snack and come back in a few minutes</h5>
        </div>
      );
    }
    if (!bookData || !audioUrl || isAudioAvailable === undefined) {
      return <Loading />;
    }
    let start = 0;
    let end = 0;
    if (textIndexes.length === 2) {
      [start, end] = textIndexes;
    }
    const { text } = bookData.pages[selectedPage - 1];
    const preText = text.substring(0, start);
    const currentWord = text.substring(start, end);
    const postText = text.substring(end);
    return (
      <>
        <Stack direction="horizontal" gap={3}>
          <div>
            <h1>
              {bookData.title}
            </h1>
          </div>
          {
            SHARE_CHANNELS.map((channel, index) => (
              <div className={index === 0 ? 'ms-auto' : ''} key={channel.name}>
                {renderButton(channel, () => this.shareStory(channel))}
              </div>
            ))
          }
        </Stack>
        <>
          <h4>
            <Button
              variant="light"
              disabled={selectedPage === 1}
              onClick={() => this.incrementPage(-1)}
            >
              <GrLinkPrevious />
            </Button>
            Page
            {' '}
            {selectedPage}
            {' '}
            of
            {' '}
            {bookData.pages.length}
            <Button
              variant="light"
              disabled={selectedPage >= bookData.pages.length}
              onClick={() => this.incrementPage(1)}
            >
              <GrLinkNext />
            </Button>
          </h4>
          <Container style={{ direction: isHebrew(text) ? 'rtl' : 'ltr' }}>
            <Row>
              <Col>
                { imageUrl ? <img src={imageUrl} alt="jenny generated" style={{ maxWidth: '90%' }} /> : <Loading />}
              </Col>
              <Col>
                <p>
                  {preText}
                  <span style={{ color: 'blue' }}>{currentWord}</span>
                  {postText}
                </p>
              </Col>
            </Row>
            { isAudioAvailable
              ? (
                <Row>
                  <Col className="m-2">
                    <ReactPlayer
                      height={30}
                      url={audioUrl}
                      width="100%"
                      playing
                      controls
                      progressInterval={100}
                      onProgress={this.onProgressAudio}
                      onEnded={this.onPageCompleted}
                    />
                  </Col>
                </Row>
              ) : undefined }
            <Row>
              <Col className="text-center">
                <Button
                  variant="info"
                  onClick={() => this.regenerateStory()}
                  className="m-1"
                  style={{ direction: 'ltr' }}
                >
                  <GiMagicLamp />
                  {' '}
                  Create another story like this one...
                </Button>
              </Col>
            </Row>
          </Container>
        </>
      </>
    );
  }
}
