import * as React from 'react';
import ReactDom from 'react-dom';
import Container from 'react-bootstrap/Container';
import 'bootswatch/dist/sketchy/bootstrap.min.css';
import { LinkContainer } from 'react-router-bootstrap';
import {
  BrowserRouter as Router, Routes, Route, useParams, useNavigate, useSearchParams,
} from 'react-router-dom';
import Navbar from 'react-bootstrap/Navbar';
import Nav from 'react-bootstrap/Nav';
import { GiMagicLamp } from 'react-icons/gi';
import { IoLibraryOutline } from 'react-icons/io5';
import { APIClient } from './APIClient';
import Book from './pages/Book';
import CreateBook from './pages/CreateBook';
import Library from './pages/Library';

const BookRender = function BookRender(
  props: {apiClient: APIClient},
) {
  const { apiClient } = props;
  const { bookId } = useParams();
  const navigate = useNavigate();

  return (
    <Book
      apiClient={apiClient}
      navigate={navigate}
      bookId={bookId || 'n/a'}
    />
  );
};

const CreateBookRender = function CreateBookRender(
  props: {apiClient: APIClient },
) {
  const { apiClient } = props;
  const [searchParams] = useSearchParams();
  const fromBookId = searchParams.get('fromBookId');
  if (fromBookId) {
    console.log(`fromBookId: ${fromBookId}`);
  }
  const navigate = useNavigate();
  return (
    <CreateBook
      apiClient={apiClient}
      navigate={navigate}
      fromBookId={fromBookId || undefined}
    />
  );
};

const LibraryRender = function LibraryRender(
  props: {apiClient: APIClient },
) {
  const { apiClient } = props;
  const navigate = useNavigate();
  return (
    <Library
      apiClient={apiClient}
      navigate={navigate}
    />
  );
};

interface MainAppState {
  apiClient: APIClient;
}

class MainApp extends React.Component {
  state: MainAppState;

  constructor(props) {
    super(props);
    this.state = { apiClient: new APIClient() };
  }

  render() {
    const { apiClient } = this.state;
    return (
      <Router>
        <Navbar variant="dark" bg="primary">
          <Container fluid>
            <LinkContainer to="/">
              <Navbar.Brand>
                Jenny
              </Navbar.Brand>
            </LinkContainer>
            <Navbar.Toggle aria-controls="basic-navbar-nav" />
            <Navbar.Collapse id="bas-c-navbar-nav">
              <Nav className="me-auto">
                <LinkContainer to="/">
                  <Nav.Link>
                    <GiMagicLamp style={{ fontSize: '30px' }} />
                    {' '}
                    New Story
                  </Nav.Link>
                </LinkContainer>
                <LinkContainer to="/library">
                  <Nav.Link>
                    <IoLibraryOutline style={{ fontSize: '30px' }} />
                    {' '}
                    Library
                  </Nav.Link>
                </LinkContainer>
              </Nav>
              <Navbar.Text>
                <i className="m-2">
                  {'Powered by '}
                  <a href="https://aws.amazon.com/generative-ai" target="_blank" rel="noreferrer">Amazon Web Services</a>
                </i>
              </Navbar.Text>
            </Navbar.Collapse>
          </Container>
        </Navbar>
        <div style={{ padding: '10px' }}>
          <Routes>
            <Route path="/book/:bookId" element={<BookRender apiClient={apiClient} />} />
            <Route path="/library" element={<LibraryRender apiClient={apiClient} />} />
            <Route path="/" element={<CreateBookRender apiClient={apiClient} />} />
          </Routes>
        </div>
      </Router>
    );
  }
}

const container = document.getElementById('app');
ReactDom.render(<MainApp />, container);
